import { Link } from "gatsby";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import imgHM from "../../assets/image/l4/png/compare.png";
import imgHC1 from "../../assets/image/l4/png/profile-1.png";
import imgHC2 from "../../assets/image/l4/png/profile-2.png";
import GlobalContext from "../../context/GlobalContext";

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="bg-default-8 pb-15 pt-24 pt-lg-32 pb-lg-28"
        id="hero-area-animation"
      >
        <Container id="animation-area-1">
          <Row className="align-items-center justify-content-center">
            <Col
              xs="9"
              md="7"
              lg="5"
              className="offset-xl-1 align-self-sm-end order-lg-2"
            >
              <div
                className="hero-img image-group-p12 position-relative mb-9 mb-sm-15 mb-lg-0"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <img className="w-100" src={imgHM} alt="" />
              </div>
              <div className="hero-sm-card-1">
                <div
                  className="px-7 py-6 d-flex bg-white shadow-10 rounded-15 w-xs-75 w-lg-70 w-xl-68 hero-card-1-animation mt-15"
                  id="an-item-1p1"
                >
                  <p className="gr-text-11 mb-0 text-mirage-2">
                    <Trans>trustpilot.q1-hero</Trans>
                  </p>
                  <div className="small-card-img ml-6">
                    <img src={imgHC1} alt="" className="circle-42" />
                  </div>
                </div>
              </div>
              <div className="animation-item">
                <div
                  className="px-7 py-6 d-flex bg-white shadow-9 rounded-15 w-xs-80 w-xl-68 hero-sm-card-2 hero-card-2-animation"
                  id="an-item-1p2"
                >
                  <div className="small-card-img mr-6 text-white">
                    <img src={imgHC2} alt="" className="circle-42" />
                  </div>
                  <p className="gr-text-11 mb-0 text-mirage-2">
                    <Trans>trustpilot.q2-hero</Trans>
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              md="9"
              className="order-lg-1 mt-9 mt-lg-0"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="hero-content">
                <h1 className="gr-text-2 mb-9">
                  <Trans>trustpilot.title</Trans>
                </h1>
                <p className="gr-text-8 pr-5 pl-0 pr-xl-11 mb-9">
                  <Trans>trustpilot.subtitle</Trans>
                </p>
                <div className="hero-btn">
                  <Link
                    to="/compare-trustpilot#compare"
                    className="btn-link with-icon text-secondary gr-text-7 font-weight-bold"
                  >
                    <Trans>trustpilot.button</Trans>{" "}
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
