import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div
        className="about-content bg-default-2 pt-lg-28 pt-13 pb-13 pb-lg-25"
        id="compare"
      >
        <Container>
          <Row>
            <Col lg="6" className="mb-7 mb-lg-0">
              <div className="content-widget-1 ps-lg-9">
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-secondary-opacity-6 me-8 mr-8">
                    <span className="text-primary--light-only gr-text-9 heading-color">
                      1
                    </span>
                  </div>
                  <div className="media-body">
                    <div className="pe-xl-13">
                      <h2 className="gr-text-3 heading-color mb-0">
                        <Trans>trustpilot.reason-1-t</Trans>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="pe-xl-15">
                <p className="gr-text-8 text-color-opacity mb-7 mb-lg-10">
                  <Trans>trustpilot.reason-1-1</Trans>{" "}
                </p>
                <p className="gr-text-8 text-color-opacity mb-7 mb-lg-10">
                  <Trans>trustpilot.reason-1-2</Trans>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
