import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/landing6-1/Content1";
import Content2 from "../sections/landing6-1/Content2";
import Content3 from "../sections/landing6-1/Content3";
import Content4 from "../sections/landing6-1/Content4";
import Content5 from "../sections/landing6-1/Content5";
import Content6 from "../sections/landing6-1/Content6";
import CTA2 from "../sections/landing6-1/CTA2";
import Hero from "../sections/landing6-1/Hero";

const CompareTrustpilot = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "left",
          button: "cta", // cta, account, null
          buttonText: "Start a free trial",
        }}
        footerConfig={{
          style: "style2",
          theme: "light",
        }}
      >
        <Header />
        <Hero />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        <Content6 />
        <CTA2 />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default CompareTrustpilot;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
